<template>
    <div class="modal-wrap">
<!--        <button class="close" @click="close_btn()"></button>-->
        <div class="top">
            <div class="profile" :class="{'no-profile': memberData.Profile.length < 1}" :style="`background-image: url(${returnImageUrl(this.memberData)})`"></div> <!--profile이 없는경우 no-profile추가-->
            <div class="name">{{memberData.mb_name}}</div>
        </div>
        <div class="content">
            <div  class="email">
                <div class="title">이메일</div>
                <div class="text">{{memberData.mb_email}}</div>
            </div>
            <div class="introduce">
                <div class="title">자기소개</div><br>
                <div class="text">
                   {{memberData.mb_memo}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "MemberDetailPageMenteeLayout",
        mixins: [imageOption],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            returnImageUrl(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(88, 88, 'png')}` : '';
            },

        },
        watch: {

        },
    }
</script>

<style scoped>

</style>